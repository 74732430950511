<template>
  <div class="qa" @click="setFaqID()">
    <div class="qa__area">
      <label class="qa__area__label--question">Q.</label>
      <p class="qa__area__text--question">{{ faq.question }}</p>
      <v-icon class="qa__area__icon" v-if="!isShow">mdi-chevron-down</v-icon>
      <v-icon class="qa__area__icon" v-else>mdi-chevron-up</v-icon>
    </div>
    <vertical-collapse-transition>
      <div class="qa__area" v-show="isShow">
        <label class="qa__area__label">A.</label>
        <p class="qa__area__text" v-html="createAutolink(faq.answer)" />
      </div>
    </vertical-collapse-transition>
  </div>
</template>

<script>
import url from '@/assets/lib/url'
import VerticalCollapseTransition from '@/components/transitions/verticalcollapse'

export default {
  components: { VerticalCollapseTransition },
  mixins: [url],
  props: {
    // FAQのオブジェクト
    faq: {
      type: Object,
      required: true
    },
    // 回答を表示しているかどうか
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    /**
     * Answerの開閉処理
     */
    setFaqID () {
      this.$emit('set-opened-faq-id', this.isShow ? null : this.faq.faqid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";

.qa {
  padding: 20px;
  background-color: $gray_lighten_color;
  border-radius: 10px;
  &__area {
    display: flex;
    margin-top: 20px;
    font-size: 1.4rem;
    text-align: left;
    &:first-child {
      margin-top: 0;
    }
    &__label {
      margin-right: 5px;
      font-weight: bold;
      &--question {
        @extend .qa__area__label;
        color: $concept_color;
      }
    }
    &__text {
      margin: 0;
      &--question {
        @extend .qa__area__text;
        font-weight: bold;
      }
    }
    &__icon {
      margin-left: auto;
      .v-icon {
        font-size: 2rem;
        color: $gray_lighten_color;
      }
    }
  }
}
</style>
