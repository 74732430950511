<script>
// アコーディオンAが開かれている場合は、
// Bを開いた時に自動でAを閉じる動作をするので、
// その閉じる動作を遅らせる時間(ms)
const CLOSE_DELAY_TIME = 80
export default {
  props: {
    // transitionの変化が始まって終わるまでの時間(ms)
    duration: {
      type: Number,
      default: 100
    },
    // transitionの変化の度合い
    // @see http://www.htmq.com/css3/transition-timing-function.shtml
    easing: {
      type: String,
      default: 'ease-out'
    }
  },
  render (h) {
    // このコンポーネントで囲まれた子を取得して、v-showやv-ifの状態を取得する
    // v-slotディレクティブなしで子（この場合FAQのAnswer）が渡された場合は$slots.defaultにchildが保持される
    // @see https://v3.ja.vuejs.org/guide/render-function.html#dom-%E3%83%84%E3%83%AA%E3%83%BC
    const child = this.$slots.default && this.$slots.default[0]

    // transition要素を作る関数
    const generateTransitionComponent = childrenOfTransition =>
      h(
        'transition',
        {
          on: {
            /**
             * enterフックに入る前 v-show=true
             * @param {String} el エレメント
             */
            beforeEnter (el) {
              el.style.height = '0'
            },
            /**
             * enterフックの開始状態 transitionが適用される
             * @param {String} el エレメント
             */
            enter (el) {
              el.style.height = `${el.scrollHeight}px`
            },
            /**
             * leaveフックに入る前 v-show=false
             * @param {String} el エレメント
             */
            beforeLeave (el) {
              el.style.transitionDelay = CLOSE_DELAY_TIME + 'ms'
              el.style.height = `${el.scrollHeight}px`
            },
            /**
             * leaveフックの開始状態 transitionが適用される
             * @param {String} el エレメント
             */
            leave (el) {
              el.style.height = '0'
            },
            /**
             * leaveフック後
             * @param {String} el エレメント
             */
            afterLeave (el) {
              el.style.transitionDelay = ''
            }
          }
        },
        childrenOfTransition
      )

    // VNodeに適用するstyle. transitionが発生する場合は、これを返す
    const wrapperData = {
      style: {
        overflow: 'hidden',
        transition: `height ${this.easing} ${this.duration}ms`
      }
    }

    // v-if="false"の場合, childはundefinedもしくはnullで返ってくる
    // Transitionの中を空のVnodeで返す
    const isEmpty = !child || child.tag === undefined
    if (isEmpty) {
      return generateTransitionComponent([])
    }

    // v-showの状態を取得
    // v-showの場合はdirectiveのnameが'show'
    const vShowDirective =
      child.data.directives &&
      child.data.directives.find(directive => directive.name === 'show')
    const isHidden = vShowDirective && !vShowDirective.value

    // v-show="false"の場合
    if (isHidden) {
      child.data.directives = [
        // 子のv-showディレクティブを消す
        ...child.data.directives.filter(
          directive => directive.name !== 'show'
        )
      ]
      // TransitionのVNodeを作る
      // VNodeはdivタグでラップする
      return generateTransitionComponent([
        h(
          'div',
          {
            ...wrapperData,
            // ラッパーにv-showディレクティブを移植する
            directives: [
              {
                name: 'show',
                value: false
              }
            ]
          },
          [child]
        )
      ])
    }

    // v-show="true"の場合
    if (vShowDirective && !isHidden) {
      // TransitionのVNodeを作る
      // VNodeはdivタグでラップする
      return generateTransitionComponent([
        h(
          'div',
          {
            ...wrapperData,
            // ラッパーにv-showディレクティブを移植する
            directives: [
              {
                name: 'show',
                value: true
              }
            ]
          },
          [child]
        )
      ])
    }

    // v-if="true"の場合
    // 特に何もせず、ラッパーに子をそのまま入れて返す
    return generateTransitionComponent([
      h(
        'div',
        wrapperData,
        [child]
      )
    ])
  }
}
</script>
