<template>
  <div class="faq">
    <qa-list class="faq__list" v-for="faq in faqs" :key="faq.faqid"
             :faq="faq" :isShow="faq.faqid === openedFaqID" @set-opened-faq-id="setOpenedFaqID" />
  </div>
</template>
<script>
import QaList from '@/components/common/qa'

export default {
  components: { QaList },
  data () {
    return {
      // 回答を開いているFAQのドキュメントID（開いてない場合はnull）
      openedFaqID: null
    }
  },
  async mounted () {
    // FAQが未取得なら取得
    if (this.faqs.length === 0) await this.$store.dispatch('faqs/getFaqs')

    // 登録されたFAQの数がゼロなら、NotFoundに飛ばす
    if (this.faqs.length === 0) {
      this.$router.push({ name: 'notfound' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} faq情報
     */
    faqs () {
      return this.$store.getters['faqs/faqs']
    }
  },
  methods: {
    /**
     * 回答を開いたFAQのドキュメントIDをセット（閉じる場合はnull）
     * @param {String} id 回答を開いたFAQのドキュメントID
     */
    setOpenedFaqID (id) {
      this.openedFaqID = id
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";

.faq {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__list {
    margin: 10px 0;
  }
}
</style>
